

@import '@core/scss/core.scss';
@import './assets/scss/styles';

@import '@core/scss/angular/libs/datatables.component.scss';
@import '@core/scss/angular/libs/select.component.scss';
@import '@core/scss/angular/libs/sweet-alerts.component.scss';
@import '@core/scss/base/bootstrap-extended/_include.scss';
@import '@core/scss/base/datatable.scss';

._table .datatable-body-row .datatable-body-cell
{
  white-space: break-spaces !important;
}

.avatar {
    background-color: $body-bg;
    margin-right: 1rem;
}

.btn-label-secondary {
    color: #a8aaae;
    border-color: #e1e1e3;
    background: #e1e1e3;
}

.bg-label-success {
    background-color: #dff7e9 !important;
    color: #28c76f !important;
}

.bg-label-danger {
    background-color: #fce5e6 !important;
    color: #ea5455 !important;
}

.circle-image{
    border-radius: 50%;
}

button:disabled{
    cursor: not-allowed;
}