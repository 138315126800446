/* @import 'bootstrap/dist/css/bootstrap.css'; */
table.dataTable {
    width: 100% !important;
    border-collapse: collapse !important;
  }
  
  table.dataTable td,
  table.dataTable th {
    padding: 0.72rem 1.5rem;
    vertical-align: middle;
  }
  
  table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc {
    padding-right: inherit;
  }
  
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    color: #6e6b7b;
    width: 14px;
    height: 14px;
    content: '';
    right: 0.3rem;
    top: 0.5rem;
  }
  
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    color: #6e6b7b;
    width: 14px;
    height: 14px;
    content: '';
    right: 0.3rem;
    top: 1.3rem;
  }
  
  table.dataTable .dt-checkboxes-cell .custom-control {
    padding-left: 0;
    padding-right: 1.25rem;
  }
  
  div.dataTables_wrapper .dt-button-collection {
    background-color: #fff;
  }
  
  div.dataTables_wrapper .dt-button-collection>div[role='menu'] {
    text-align: left;
    box-shadow: 0px 5px 16px -8px;
    min-width: 8rem;
  }
  
  div.dataTables_wrapper .dt-button-collection [class*='buttons-']:focus {
    outline: 0;
  }
  
  div.dataTables_wrapper div.dataTables_filter label,
  div.dataTables_wrapper div.dataTables_length label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  div.dataTables_wrapper div.dataTables_filter select,
  div.dataTables_wrapper div.dataTables_length select {
    background-position: calc(100% - 3px) 11px, calc(100% - 20px) 13px, 100% 0;
    padding: 0 0.8rem;
    width: 5rem;
    margin: 0 0.5rem;
  }
  
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.75rem;
  }
  
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  
  div.dataTables_wrapper .dataTables_info {
    padding-top: 1.25rem !important;
    color: #b9b9c3;
  }
  
  tr.group {
    background-color: #fafafc;
  }
  
  .progress-linear {
    display: block;
    position: sticky;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    top: 0;
  }
  
  .progress-linear .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    -webkit-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
    background-color: rgb(230, 239, 124);
  }
  
  .progress-linear .container .bar {
    transition: all 0.2s linear;
    -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    background-color: #d9ce20;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
  }
  
  @keyframes query {
    0% {
      opacity: 1;
      transform: translateX(35%) scale(0.3, 1);
    }
  
    100% {
      opacity: 0;
      transform: translateX(-50%) scale(0, 1);
    }
  }
  
  
  @media only screen and (max-width: 768px) {
    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
      justify-content: center;
    }
  
    div.dataTables_wrapper div.dataTables_info {
      white-space: inherit;
    }
  
    div.dt-buttons {
      justify-content: center;
      margin-top: 1rem;
    }
  
    div.dt-buttons button {
      margin-bottom: 0.5rem;
    }
  }
  
  @media only screen and (max-width: 599.9px) {
    .dtr-bs-modal .modal-body {
      padding-left: 0;
      padding-right: 0;
      overflow-x: scroll;
    }
  
    .dtr-bs-modal .table.dtr-details {
      margin: 0 auto 1rem;
    }
  
    .dtr-bs-modal .table.dtr-details td {
      padding-left: 0;
      padding-right: 0;
    }
  
    .dtr-bs-modal .table.dtr-details tr td:first-child {
      padding-left: 0.75rem;
    }
  
    div.dataTables_wrapper {
      overflow: scroll;
    }
  }